//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import Modal from '../Modal';
import Trans from '../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './step-up-modal.scss';

const StepUpModal = () => {
  const dispatch = useDispatch();

  return (
    <Modal name="StepUpModal" className="step-up-modal">
      <h2 className="step-up-modal-title">
        <Trans file="Modals" id="StepUp_Title" />
      </h2>
      <p className="step-up-modal-message">
        <Trans file="Modals" id="StepUp_Description_One" />
      </p>
      <p className="step-up-modal-message-bold">
        <Trans file="Modals" id="StepUp_Description_Two" />
      </p>
      <p className="step-up-modal-message">
        <Trans file="Modals" id="StepUp_Description_Three" />
      </p>
      <div className="step-up-modal-btns">
        <ButtonPrimary onClick={() => dispatch(closeModal('StepUpModal'))}>
          <Trans file="Labels" id="okGotIt" />
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

export default StepUpModal;
