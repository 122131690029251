// Helpers
import { toResendOTP } from '../../lib/helpers';
import { setServerErrors, ERRORS_MAP } from '../../lib/validation';

// Actions
import { loginOTPTimeout } from '../login/loginActions';

import {
  pushMessageBanner,
  clearMessageBanner,
} from '../../reducers/messageBanner/messageBannerActions';

// Consts
export const OTP_SEND_CODE_REQUESTED = '[OTP] Send Code Requested';
export const OTP_SEND_CODE_SUCCESS = '[OTP] Send Code Success';
export const OTP_VERIFY_CODE_REQUESTED = '[OTP] Verify Code Requested';
export const OTP_VERIFY_CODE_REQUESTED_CREATE_ACCOUNT =
  '[API] OTP Verify Code Requested Create Account';
export const OTP_VERIFY_CODE_SUCCESS = '[OTP] Verify Code Success';
export const OTP_SET_DATA = '[OTP] Set Data';
export const OTP_CLEAR_STATE = '[OTP] Clear State';
export const OTP_MESSAGE_TYPES = {
  RESET_USER_PASSWORD: 'RESET_USER_PASSWORD',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  UPDATE_USER_NAME: 'UPDATE_USER_NAME',
  UPDATE_CONTACT: 'UPDATE_CONTACT',
  UPDATE_WORK_EMAIL: 'UPDATE_WORK_EMAIL',
};

export const sendOTP = payload => dispatch =>
  dispatch({ type: OTP_SEND_CODE_REQUESTED, payload });

export const verifyOTP = payload => dispatch =>
  dispatch({ type: OTP_VERIFY_CODE_REQUESTED, payload });

export const verifyOTPCreateAccount = payload => dispatch =>
  dispatch({ type: OTP_VERIFY_CODE_REQUESTED_CREATE_ACCOUNT, payload });

export const clearOTPState = () => dispatch =>
  dispatch({ type: OTP_CLEAR_STATE });

export const resendOTP = ({
  OTP,
  resendCodeActionCreator,
  onResendCodeSuccess,
  setResendCodeStatus,
  channelCode,
  formikProps: { setFormikState },
}) => async dispatch => {
  setResendCodeStatus('sending');
  const action = resendCodeActionCreator
    ? ({ data }) => resendCodeActionCreator(data)
    : ({ data }) => sendOTP(data);
  const [
    { body, response },
    { body: errorBody, response: errorResponse },
  ] = await toResendOTP(dispatch(action(OTP)));
  const currentPath = window.location.pathname.substr(1);
  const bannerMessage =
    currentPath === 'sign-in' ? 'SignInOTPLimitReached' : 'OTPLimitReached';
  if (errorBody && errorBody.errorCode === '401') {
    if (
      errorBody.errorDescription &&
      errorBody.errorDescription === 'Retry limit has been reached'
    ) {
      const messageIdTime = Date.now();
      return dispatch(
        pushMessageBanner([
          {
            type: 'RetryLimitError',
            messageId: messageIdTime.toString(),
            textEnglish: `{"type": "Modals", "id": "${bannerMessage}"}`,
            textSpanish: `{"type": "Modals", "id": "${bannerMessage}"}`,
            startTime: '2021-10-01 18:35:00.0',
            endTime: '2040-06-30 12:05:00.0',
            priority: '2',
            status: 'R',
            author: 'USER',
            valid: true,
          },
        ])
      );
    }
  }
  if (response && response.status !== 200) {
    if (body.failureUrl && body.failureUrl === 'OTP entered has expired')
      return dispatch(loginOTPTimeout());

    setResendCodeStatus('error');
    if (body.errorMessage && body.errorMessage.includes('timeout'))
      body.errors = {
        ERROR: [
          'Your authentication session timed out. Please refresh and try signing in again.',
        ],
      };
    if (!body.errors)
      body.errors = {
        ERROR: [
          'There was a problem sending your code. Please refresh and try signing in again.',
        ],
      };
    setServerErrors({ body, setFormikState, errorsMap: ERRORS_MAP.SEND_OTP });
  }

  if (response && response.status === 200) {
    setResendCodeStatus('success');
    if (channelCode === 'SMS') {
      setTimeout(() => setResendCodeStatus('resendDelay'), 1000);
    }
    if (typeof onResendCodeSuccess === 'function')
      onResendCodeSuccess({ body, response });
  }

  if (channelCode === 'SMS') {
    setTimeout(() => setResendCodeStatus('default'), 30000);
  } else {
    setTimeout(() => setResendCodeStatus('default'), 2000);
  }
};
