// @flow

export function formatPhoneNumber(phoneNumber: string) {
  if (phoneNumber.length < 1 || phoneNumber.includes('*')) return phoneNumber;
  const number = cleanPhoneNumber(phoneNumber);
  return `${number.slice(0, 3)}-${+number.slice(3, 6)}-${number.slice(6)}`;
}

export function cleanPhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/[^\d.]/g, '');
}
