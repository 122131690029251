// Vendor
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { replace, push, goBack } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

// Actions
import { getMyApps } from '../../../reducers/agency/agencyActions';
import {
  getAppParamsForLink,
  verifyAppLink,
} from '../../../reducers/agency/linkService/linkServiceActions';
import { openModal } from '../../../reducers/modal/modalActions';

// Selectors

import {
  selectAgencyByAppRouteParam,
  selectAppByRouteParam,
} from '../../../reducers/agency/agencySelectors';

// Components
import { LINK_SERVICES_STEPS } from '.';
import {
  DpsLinkingLockoutModal,
  LoadingDots,
  Trans,
} from '../../../components';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';
import LinkServiceLookupForm from '../../../components/common/Forms/LinkServiceLookupForm/LinkServiceLookupForm';
import { setTakeoverNavContent } from '../../../reducers/navbar/navbarActions';
import { DPS_USER_LOCKOUT_MODAL, LinkingGuidanceModal } from '../../../components';

// Helpers
import { getAppDataBySlug } from '../../../lib/helpers';

const LinkServiceLookup = ({
  setTakeoverNavContent,
  app,
  agency,
  getAppParamsForLink,
  link,
  goBack,
  match,
  verifyAppLink,
  push,
  getMyApps,
}) => {
  const getAppParams = () => {
    getAppParamsForLink({ app, agency });
  };
  const { t } = useTranslation('Labels');
  useEffect(() => {
    const appExists = !!getAppDataBySlug(match.params.appSlug);

    if (appExists) {
      if (!agency || !app) getMyApps().then(() => getAppParams());
      else getAppParams();
    } else replace('/link');

    setTakeoverNavContent({
      title:
        link && link.app && link.app.appSlug
          ? t(`${link.app.appSlug}_LinkALicenseOrRegistration`)
          : t(`LinkALicenseOrRegistration`),
    });
  }, []);

  const handleSubmit = (values, formikActions) => {
    verifyAppLink(values, formikActions)
      .then(() => push(`/link/${LINK_SERVICES_STEPS.CONFIRM}`))
      .catch(e => {
        //ERROR HERE
        console.log('ERROR HERE', e);
      });
  };

  const dispatch = useDispatch();

  // const relinkLabel = link.relinkData ? '_Relink' : '';

  if (!link || !link.params || !agency || !app) return <LoadingDots />;

  //Fix Trans below, should move to correct file, no conditional statement needed
  return (
    <>
      <h1 className="h2">
        <Trans
          file={agency.agencySlug}
          id={`${app.appSlug}_LinkService_LookupTitle`}
        />
      </h1>
      {app.appSlug == 'dmvrr' && (
        <>
          <Trans
            file={agency.agencySlug}
            id={`${app.appSlug}_LinkService_LookupSubTitle`}
          />
          <a className="linking-guidance-text" onClick={() => dispatch(openModal('LinkingGuidanceModal'))}>
            <Trans
              file={agency.agencySlug}
              id={`${app.appSlug}_LinkService_LookupVehicleLinkReq`}
            />
          </a>
        </>
      )}
      {link.selected && (
        <SummaryCard className="mb-5">
          <SummaryCard.List
            items={[
              {
                className: 'word-break',
                label: 'Board',
                value: link.selected.program.programName,
              },
              {
                label: (
                  <Trans
                    file="Labels"
                    id="LicenseName"
                    fallback="License Name"
                  />
                ),
                value: link.selected.license.licenseType,
              },
            ]}
          />
        </SummaryCard>
      )}

      <LinkServiceLookupForm
        appParams={link.params}
        agency={agency}
        app={app}
        reLinkData={link.relinkData}
        onSubmit={handleSubmit}
        onCancel={goBack}
      />
      <DPS_USER_LOCKOUT_MODAL />
      <DpsLinkingLockoutModal />
      <LinkingGuidanceModal />
    </>
  );
};

const mapStateToProps = (state, props) => ({
  link: state.agency.link,
  agency: selectAgencyByAppRouteParam(state, props),
  app: selectAppByRouteParam(state, props),
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      verifyAppLink,
      replace,
      push,
      goBack,
      getMyApps,
      getAppParamsForLink,
      setTakeoverNavContent,
    },
    dispatch
  );

const ConnectedLinkServiceLookup = connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkServiceLookup);

const RouteForConnectedLinkServiceLookup = () => (
  <Route
    path="/link/lookup/:appSlug"
    render={props => <ConnectedLinkServiceLookup {...props} />}
  />
);

LinkServiceLookup.propTypes = {
  link: PropTypes.object.isRequired,
  agency: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  replace: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  verifyAppLink: PropTypes.func.isRequired,
  getMyApps: () =>
    // Promise
    PropTypes.objectOf({
      then: PropTypes.object,
      catch: PropTypes.object,
    }).isRequired,
  getAppParamsForLink: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  setTakeoverNavContent: PropTypes.func.isRequired,
};

export default RouteForConnectedLinkServiceLookup;