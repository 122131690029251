//@flow
import { TextButton } from 'texkit/dist/components/Buttons';

// Vendors
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Helpers
import { publicURL } from '../../../../lib/helpers';

// Components
import { Modal } from '..';
import { Trans } from '../../';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './license-details-modal.scss';

const LicenseDetailsModal = () => {
  const [isVertical, setIsVertical] = useState(false);
  const dispatch = useDispatch();

  return (
    <Modal
      name="LicenseDetailsModal"
      className="license-details-modal"
      disableCloseButton
      onOpen={() => setIsVertical(false)}
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Modals"
          id="FindYourID"
          fallback="Find your ID and DD/Audit numbers."
        />
      </h2>
      <div className="modal-content">
        <Trans
          file="Modals"
          id="LicenseOrIDNumber"
          fallback="Driver License or ID number"
        />
        <div className="modal-graphic">
          <img
            src={publicURL(`dl-example${isVertical ? '-vertical' : ''}.png`)}
            alt={<Trans file="Modals" id="LicenseDetailsImageAlt" />}
          />
        </div>
        <Trans file="Modals" id="AuditNumber" />
        <div className="toggle-card-display">
          <TextButton onClick={() => setIsVertical(!isVertical)}>
            <Trans
              file="Labels"
              id={isVertical ? 'ShowHorizontalCard' : 'ShowVerticalCard'}
            />
          </TextButton>
        </div>
        <ButtonPrimary
          className="modal-close-button"
          onClick={() => dispatch(closeModal('LicenseDetailsModal'))}
        >
          <Trans file="Labels" id="okGotIt" />
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

export default LicenseDetailsModal;
