// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './dont-have-id-modal.scss';

// Components
import { Trans } from '../../..';
import {
  TxButton,
  TxButtonMode,
  TxModal,
  TxModalBody,
  TxModalFooter,
  TxModalHeader,
} from 'texkit-ui/components';

const DontHaveIdModal = ({ closeModal, open, showCloseBtn = true }) => {
  return (
    <TxModal
      open={open}
      onClose={closeModal}
      showCloseBtn={showCloseBtn}
      name="DontHaveIdModal"
      className="dont-have-id-modal"
    >
      <TxModalHeader className="test">
        <p className="modal-heading">
          <Trans file="Modals" id="DontHaveId" fallback="" />
        </p>
      </TxModalHeader>
      <TxModalBody className="modal-body">
        <p className="modal-body">
          <Trans file="Modals" id="CallHelpDesk" fallback="" />
        </p>
      </TxModalBody>
      <TxModalFooter>
        <TxButton mode={TxButtonMode.Primary} onClick={closeModal}>
          <Trans file="Modals" id="Ok" fallback="" />
        </TxButton>
      </TxModalFooter>
    </TxModal>
  );
};

DontHaveIdModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showCloseBtn: PropTypes.bool.isRequired,
};

export default DontHaveIdModal;
