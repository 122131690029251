// Vendors
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { DateTime, Duration } from "luxon";

// Actions
import { openModal, closeModal } from "../../reducers/modal/modalActions";
import {
  endSession as endSessionAction,
  updateSession,
} from "../../reducers/session/sessionActions";
import { keepAliveRequest } from "../../backend";

// Selectors
import {
  selectSessionData,
  selectTimeoutModal,
} from "../../reducers/session/sessionSelectors";
import { selectIsAuthenticated } from "../../reducers/auth/authSelectors";
import initialState from "../../store/initialState";

// Components
import WarningModalWrapper from "../common/Modals/WarningModalWrapper/WarningModalWrapper";

// Helpers
import { throttle } from "../../lib/helpers";

const SessionManager = ({ children }) => {
  const dispatch = useDispatch();
  const sessionData = useSelector(selectSessionData);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const timeoutModal =
    useSelector(selectTimeoutModal) || initialState.session.timeoutModal;
  
  const sessionTimeout = 780000;

  const [userActionFlag, setUserActionFlag] = useState(false);
  const [latestAccessTime, setLatestAccessTime] = useState(null);
  const [warningDuration, setWarningDuration] = useState(
    Duration.fromObject({ minutes: timeoutModal.duration })
  );
  const [duration, setDuration] = useState(timeoutModal.duration);
  const [meaningfulTime, setMeaningfulTime] = useState(
    sessionTimeout
  );

  const meaningfulTimeoutRef = useRef(null);
  const warningCountdownRef = useRef(null);
  const warningTimerRef = useRef(null);
  const sessionTimerRef = useRef(null);

  const setupTimer = (ref, callback, delay) => {
    console.log('setupTimer() called');

    clearTimeout(ref.current);
    ref.current = setTimeout(callback, delay);
  }

  // Scenario of page refresh or component mount, taken from old componentDidMount()
  useEffect(() => {
    console.log('useEffect() 1 called');

    const { maxIdleExpirationTime } = sessionData;

    setupTimer(
      sessionTimerRef, 
      hasUserTakenAction, 
      intervalUntilExpiration(maxIdleExpirationTime)
    );

    initializeMeaningfulTimeout();

    if (Date.parse(maxIdleExpirationTime) - Date.parse(new Date()) < 0) {
      keepAlive();
    }

    return () => {
      clearTimeout(meaningfulTimeoutRef.current);
      clearTimeout(sessionTimerRef.current);
      removeEventListeners();
    };
  }, []);

  // Scenario of changes in session data, taken from old UNSAFE_componentWillReceiveProps()
  useEffect(() => {
    console.log('useEffect() 2 called');

    if (!sessionData || !sessionData.latestAccessTime) {
      return;
    }

    const { maxIdleExpirationTime } = sessionData;

    setWarningDuration(Duration.fromObject({ minutes: timeoutModal.duration }));
    setDuration(timeoutModal.duration);
    setMeaningfulTime(sessionTimeout);

    if (
      sessionData.latestAccessTime !== latestAccessTime &&
      isRecent(sessionData.latestAccessTime)
    ) {
      setupTimer(
        sessionTimerRef, 
        hasUserTakenAction, 
        intervalUntilExpiration(maxIdleExpirationTime)
      );
    }

    return () => {
      clearTimeout(sessionTimerRef.current);
    }
  }, [sessionData]);

  const meaningfulActionTriggered = throttle((event) => {
    console.log('meaningfulActionTriggered() called');

    if (!isMeaningfulEvent(event) || !isAuthenticated) {
      return;
    }

    removeEventListeners();
    setUserActionFlag(true);
    initializeMeaningfulTimeout();
  }, 200);

  const initializeCountdown = () => {
    console.log('initializeCountdown() called');

    setWarningDuration(Duration.fromObject({ minutes: duration }));

    setupTimer(warningCountdownRef, decrementWarningDuration, 1000);
    setupTimer(warningTimerRef, endSession, duration * 60 * 1000);
  };

  const decrementWarningDuration = () => {
    console.log('decrementWarningDuration() called');

    setWarningDuration(prevDuration => prevDuration.minus({ second: 1 }))
    setupTimer(warningCountdownRef, decrementWarningDuration, 1000);
  };

  const stopCountdown = () => {
    console.log('stopCountdown() called');

    clearInterval(warningCountdownRef.current);
    clearTimeout(warningTimerRef.current);
    clearTimeout(meaningfulTimeoutRef.current);
  };

  const initializeMeaningfulTimeout = () => {
    console.log('initializeMeaningfulTimeout() called');

    addEventListeners();

    setupTimer(meaningfulTimeoutRef, beginSessionWarning, sessionTimeout);
  };

  const beginSessionWarning = () => {
    console.log('beginSessionWarning() called')

    removeEventListeners();
    dispatch(openModal(timeoutModal.name));
  };

  const hasUserTakenAction = () => {
    console.log('hasUserTakenAction() called');

    removeEventListeners();
    if (userActionFlag) {
      keepAlive();
    }
  };

  const userClickModal = () => {
    console.log('userClickModal() called');

    keepAlive();
    dispatch(closeModal(timeoutModal.name));
  };

  const beginMeaningfulWatch = () => {
    console.log('beginMeaningfulWatch() called');

    removeEventListeners();
    initializeMeaningfulTimeout();
  };

  const addEventListeners = () => {
    console.log('addEventListeners() called');

    window.addEventListener("scroll", meaningfulActionTriggered, true);
    window.addEventListener("click", meaningfulActionTriggered, true);
    window.addEventListener("focusin", meaningfulActionTriggered, true);
  };

  const removeEventListeners = () => {
    console.log('removeEventListeners() called');

    window.removeEventListener("scroll", meaningfulActionTriggered, true);
    window.removeEventListener("click", meaningfulActionTriggered, true);
    window.removeEventListener("focusin", meaningfulActionTriggered, true);
  };

  const isRecent = (ISO) => {
    console.log('isRecent() called');

    return (
      DateTime.local().toMillis() - DateTime.fromISO(ISO).toMillis() <
      meaningfulTime
    );
  };

  const isMeaningfulEvent = (event) => {
    console.log('isMeaningfulEvent() called with event: ', event.type);

    if (!event) return false;
    return (
      event.type === "scroll" || event.type === "click" || event.type === "focusin" ||
      (event.type === "click" &&
        (event.target.nodeName === "BUTTON" ||
          event.target.nodeName === "A")) ||
      (event.type === "focusin" && event.target.nodeName === "INPUT")
    );
  };

  const keepAlive = () => {
    console.log('keepAlive() called');

    dispatch(
      keepAliveRequest(process.env.REACT_APP_FORGEROCK_URL + "/refreshToken")
    )
      .then(({ body }) => {
        if (body.expires_in) {
          dispatch(updateSession(body.expires_in));
          localStorage.setItem("access_token", body.access_token);
          setUserActionFlag(false);
          beginMeaningfulWatch();
          setupTimer(
            sessionTimerRef,
            hasUserTakenAction,
            intervalUntilExpiration(body.expires_in)
          )
        }
      })
      .catch(() => {
        endSession();
      });
  };

  const endSession = () => {
    console.log('endSession() called');

    removeEventListeners();
    clearTimeout(meaningfulTimeoutRef.current);
    clearInterval(warningCountdownRef.current);
    clearTimeout(warningTimerRef.current);
    clearTimeout(sessionTimerRef.current);
    dispatch(endSessionAction(timeoutModal.warning));
  };

  const intervalUntilExpiration = (expires) => {
    console.log('intervalUntilExpiration() called');

    return DateTime.fromISO(expires).minus({ seconds: 30 }).toMillis() -
    DateTime.local().toMillis()
  }

  return (
    <>
      {children}
      <WarningModalWrapper
        name={timeoutModal.name}
        duration={warningDuration}
        onOpen={initializeCountdown}
        onClose={stopCountdown}
        keepAlive={userClickModal}
      />
    </>
  )
};

SessionManager.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SessionManager;