const urlLang = window.location.pathname.split('/').includes('es')
  ? 'es'
  : localStorage.getItem('i18nextLng')
  ? localStorage.getItem('i18nextLng')
  : 'en';
const initialState = {
  agency: {
    agencyIds: [],
    agencies: {},
    appIds: [],
    apps: {},
    linkUserKeys: [],
    links: {},
  },
  alert: {},
  app: {},
  auth: {
    isPermittedToAuthenticateViaIdology: true,
  },
  content: {},
  createAccount: {},
  forgotPassword: {},
  forgotUsername: {},
  language: {
    selected: urlLang,
    options: [
      {
        label: 'English',
        value: 'eng',
        code: 'en',
      },
      {
        label: 'Español',
        value: 'esp',
        code: 'es',
      },
    ],
  },
  login: {},
  mobileNav: {},
  modal: {},
  navbar: {},
  OTP: {},
  user: {
    todo: [],
  },
  session: {
    timeoutModal: {
      name: 'SessionWarningModal',
      duration: 2,
      warning: {},
    },
  },
  onboarding: {},
  bnlr: {},
  dps: {
    selected: '',
  },
  tdlr: {
    selected: '',
  },
  dmv: {
    selected: '',
  },
  cybersecurity: {},
  payment: { applicationId: 'MGMW' },
  appHistory: {
    activity: 6,
    transactions: 6,
  },
  messageBanner: {
    messages: [],
    dismissed: [],
  },
};

export default initialState;
