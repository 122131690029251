/* eslint-disable no-unused-vars */
//@flow

// Vendors
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Actions
import {
  linkAppLink,
  linkServiceSuccess,
} from '../../../reducers/agency/linkService/linkServiceActions';
import { clearAlertInfo } from '../../../reducers/alert/alertActions';

// Components
import { FormActions, FormActionsItem, Trans } from '../../../components';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Helpers
import { scrollToTop } from '../../../lib/helpers';

import Alert from 'texkit/dist/components/Alert/Alert';

const DPSLinkServiceConfirm = ({
  firstName,
  middleName,
  dlrLastName,
  suffix,
  licenseType,
  licenseClass,
  expirationDate,
  renewalEligibility,
  duplicateEligibility,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => dispatch(clearAlertInfo());
  }, []);

  const [submitting, setSubmitting] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);
  const { t } = useTranslation('dps');

  const handleConfirmClick = () => {
    setIsDismissed(false);
    setSubmitting(true);
    dispatch(linkAppLink())
      .then(() => dispatch(linkServiceSuccess()))
      .catch(() => {
        scrollToTop();
        setSubmitting(false);
      });
  };

  return (
    <>
      <h1 className="h2">
        <Trans file="dps" id="dpslr_LinkService_ConfirmTitleDefault" />
      </h1>

      <p>
        <Trans file="dps" id="dpslr_LinkService_ConfirmDescriptionDefault" />
      </p>
      <>
        <SummaryCard>
          <SummaryCard.List
            items={[
              {
                label: <Trans file="Labels" id="Name" />,
                value: `${firstName ? firstName : ''} ${
                  middleName ? middleName : ''
                } ${dlrLastName ? dlrLastName : ''} ${suffix ? suffix : ''}`,
              },

              {
                label: <Trans file="Labels" id="Type" />,
                value: `${
                  licenseType === 'DL'
                    ? t('dps_Driver_License')
                    : licenseType === 'CDL'
                    ? t('cdl_Driver_License')
                    : t('dps_IDCard_Label')
                }`,
              },
              licenseClass !== null
                ? {
                    label: <Trans file="Labels" id="Class" />,
                    value: licenseClass,
                  }
                : {
                    label: <Trans file="Labels" id="Expiration" />,
                    value: moment(expirationDate).format('MM/DD/YYYY'),
                  },
              licenseClass !== null && {
                label: <Trans file="Labels" id="Expiration" />,
                value: moment(expirationDate).format('MM/DD/YYYY'),
              },
            ]}
          />
        </SummaryCard>

        {!renewalEligibility && !duplicateEligibility && (
          <Alert
            alert={{
              info: {
                type: 'warning',
                title: (
                  <Trans
                    file="dps"
                    id="dpslr_link_service_unavailable_title"
                    fallback="dpslr_link_service_unavailable_title"
                  />
                ),
                message: (
                  <Trans
                    file="dps"
                    id="dpslr_link_service_unavailable_desc"
                    fallback="dpslr_link_service_unavailable_desc"
                  />
                ),
                closeable: false,
              },
            }}
            handleClose={() => setIsDismissed(true)}
          />
        )}
      </>
      <FormActions>
        <FormActionsItem>
          <ButtonPrimary
            onClick={handleConfirmClick}
            isLoading={submitting}
            loadingContent={
              <Trans file="Labels" id="Linking" fallback="Linking" />
            }
          >
            <Trans
              file="Labels"
              id="LinkToMyAccount"
              fallback="Link To My Account"
            />
          </ButtonPrimary>
        </FormActionsItem>
        <FormActionsItem>
          <Button onClick={() => dispatch(goBack())} disabled={submitting}>
            <Trans file="Labels" id="Back" fallback="Back" />
          </Button>
        </FormActionsItem>
      </FormActions>
    </>
  );
};

DPSLinkServiceConfirm.propTypes = {
  firstName: PropTypes.string.isRequired,
  middleName: PropTypes.string.isRequired,
  dlrLastName: PropTypes.string.isRequired,
  suffix: PropTypes.string.isRequired,
  licenseType: PropTypes.string.isRequired,
  licenseClass: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  renewalEligibility: PropTypes.string.isRequired,
  duplicateEligibility: PropTypes.string.isRequired,
};

export default DPSLinkServiceConfirm;
