// @flow

// Vendors
import React from 'react';

// Components
import { ProgressBar } from '..';

// Styles
import './takeover-page-header.scss';
import { Trans } from '../../../components';



type TakeoverPageHeaderProps = {
  preTitle: string,
  title: string,
  steps: Array<{ component: Node, id: string }>,
  activeStepId: string,
  hasProgressBar: boolean,
  hasStepCount: boolean,
  ignoreSteps: Array<{ component: Node, id: string }>,
};

const TakeoverPageHeader = ({
  preTitle,
  title,
  steps,
  activeStepId,
  hasProgressBar = false,
  hasStepCount = false,
  ignoreSteps = [],
}: TakeoverPageHeaderProps) => {
  if (ignoreSteps.includes(activeStepId)) return null;

  //The code below will check if "step" is present on the
  //active step object,if not, index of step will be step number

  const filteredSteps = steps.filter(step => !ignoreSteps.includes(step.id));

  const totalSteps = filteredSteps
    .map(item => item.step)
    .filter((value, index, self) => self.indexOf(value) === index || !value);

  const activeStepIndex = filteredSteps.findIndex(
    step => step.id === activeStepId
  );

  const activeStep = filteredSteps[activeStepIndex].step
    ? filteredSteps[activeStepIndex].step
    : activeStepIndex + 1;

  return (
    <>
      <div className="takeover-page-header">
        <div>{preTitle}</div>
        <div>
          <strong>{title}</strong>
        </div>
        {hasProgressBar && (
          <ProgressBar fraction={activeStep / totalSteps.length} />
        )}
      </div>

      {hasStepCount && (
        <p className="takeover-page-header-step-count">
     <strong><Trans file='Labels' id="Step" fallback='Step'></Trans> {activeStep} </strong><Trans file='Labels' id="of"></Trans> {totalSteps.length}

        </p>
      )}
    </>
  );
};

export default TakeoverPageHeader;
