// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputPassword, Trans } from '../../../../common';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';
/**
 * @visibleName SSN
 */
const InputSSN = ({ className, mask, maxLength, ...props }) => {
  return (
    <InputPassword
      {...props}
      mask={mask}
      maxLength={maxLength}
      className={classnames('input-ssn', className)}
    />
  );
};

InputSSN.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
  label: PropTypes.string,
  autoComplete: PropTypes.string,
  mask: PropTypes.array,
  maxLength: PropTypes.number,
};

InputSSN.defaultProps = {
  label: <Trans file="Labels" id="SSN" fallback="SSN" />,
  name: 'lastFourSSN',
  autoComplete: 'lastFourSSN',
  mask: [...Array(4)].map(() => /\d/),
  maxLength: 4,
};

export default InputSSN;
