// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputGroup, InputPhone, InputRadioGroup, Trans } from '../../..';

import { TextButton } from 'texkit/dist/components/Buttons';

// Styles
import './input-group-phone.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Phone
 */
const InputGroupPhone = ({
  className,
  inverse,
  formikProps,
  options = [
    {
      id: 'phoneTypeMobile',
      label: <Trans file="Labels" id="Mobile" fallback="Mobile" />,
      value: 'SMS',
    },
    {
      id: 'phoneTypeOther',
      label: <Trans file="Labels" id="Other" fallback="Other" />,
      value: 'TALK',
    },
  ],
  label,
  ...props
}) => {
  return (
    <>
      <InputGroup
        {...props}
        className={classnames('input-group-phone', className)}
      >
        <InputPhone
          className="input-phone"
          name="phone"
          formikProps={formikProps}
          label={label}
        />

        <TextButton
          as="a"
          onClick={e => props.open(e)}
          className="sms-opt-in-text"
        >
          <Trans
            file="Account"
            id="SMSOptInText"
            fallback="Message and data rates may apply"
          />
        </TextButton>

        <InputRadioGroup
          name="phoneType"
          options={options}
          inverse={inverse}
          inline={true}
          formikProps={formikProps}
        />
      </InputGroup>
    </>
  );
};

InputGroupPhone.propTypes = {
  className: PropTypes.string,
  inverse: PropTypes.bool.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
  options: PropTypes.array,
  label: PropTypes.string,
  open: PropTypes.func,
};

export default InputGroupPhone;
