// @flow

import { REQUEST_TYPES } from '.';

export const userGetUserRequest = () => ({
  type: `[API] Get User`,
  url: '/user/ui/getUser',
  APIMethod: REQUEST_TYPES.GET,
});

export const userExistsRequest = (email?: string) => ({
  type: `[API] User Exists`,
  url: `/user/ui/userExists/${email || ''}`,
  APIMethod: REQUEST_TYPES.GET,
});

export const workEmailExistsRequest = (email?: string) => ({
  type: `[API] Work Email Exists`,
  url: `/user/ui/workEmailExists/${email || ''}`,
  APIMethod: REQUEST_TYPES.GET,
});

export const userDeactivateUserRequest = payload => ({
  type: `[API] Deactivate User`,
  url: '/user/ui/update/delete/blitz',
  APIMethod: REQUEST_TYPES.POST,
  body: payload,
});

export const userGetContactsRequest = () => ({
  type: `[API] Get User Contacts`,
  url: '/user/ui/contact',
  APIMethod: REQUEST_TYPES.GET,
});

export const userGetAddressesRequest = () => ({
  type: `[API] Get User Address`,
  url: '/user/ui/address',
  APIMethod: REQUEST_TYPES.GET,
});

export const userUpdateAddressRequest = (
  body: userUpdateAddressRequestType
) => ({
  type: `[API] Update Address`,
  url: '/user/ui/update/address',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const userGetAllSecurityQuestionsRequest = () => ({
  type: `[API] Get All Security Questions`,
  url: '/user/qa/questions',
  APIMethod: REQUEST_TYPES.GET,
});

export const userGetUserSecurityQuestionsRequest = () => ({
  type: `[API] Get User Security Questions`,
  url: '/user/qa/questions/user',
  APIMethod: REQUEST_TYPES.GET,
});

export const userUpdateUserSecurityQuestionsRequest = (
  body: userUpdateSecurityQuestionsRequestType
) => ({
  type: `[API] Update User Security Questions`,
  url: '/user/qa/answers',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const userUpdatePasswordRequest = (
  body: userUpdatePasswordRequestType
) => ({
  type: `[API] Update Password`,
  url: '/user/ui/update/password',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const userVerifyAddressRequest = body => {
  if (body.state && body.state.value) body.state = body.state.value;
  return {
    type: `[API] Verify Address`,
    url: '/user/ui/verify/address',
    APIMethod: REQUEST_TYPES.POST,
    body,
  };
};

export const userVerifyUserNameRequest = ({
  body,
  headers,
}: userVerifyUserNameRequestType) => ({
  type: `[API] Verify/Update User Name`,
  url: '/user/ui/update/verify/userName',
  APIMethod: REQUEST_TYPES.POST,
  body,
  headers,
});

export const userVerifyContactRequest = ({
  body,
  headers,
}: userVerifyContactRequestType) => ({
  type: `[API] Verify/Update Contact`,
  url: '/user/ui/update/verify/contact',
  APIMethod: REQUEST_TYPES.POST,
  body,
  headers,
});

export const userAddUpdateContactRequest = ({
  body,
}: userAddUpdateContactRequestType) => ({
  type: `[API] Add/Update Contact`,
  url: '/user/ui/update/contact',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const userUpdateProfileInfoRequest = ({
  body,
}: userUpdateProfileInfoRequestType) => ({
  type: `[API] Update Profile Info`,
  url: '/user/ui/update/info',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const userDeleteContactRequest = ({ body }: { body: any }) => ({
  type: `[API] Delete Profile Contact`,
  url: '/user/ui/delete/contact',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const userGetTodoItemsRequest = () => ({
  type: `[API] Get User Todo Items`,
  url: '/user/ui/todo',
  APIMethod: REQUEST_TYPES.GET,
});

export const userGetTransactions = {
  type: '[API] Get User Transactions',
  url: '/user/ui/transactions',
  APIMethod: REQUEST_TYPES.GET,
};

export const userGetSubscriptionsRequest = () => ({
  type: `[API] Get User Subscriptions`,
  url: '/user/ui/subscriptions',
  APIMethod: REQUEST_TYPES.GET,
});

export const userUpdateSubscriptionsRequest = payload => ({
  type: `[API] Update User Subscriptions`,
  url: '/user/ui/subscriptions',
  APIMethod: REQUEST_TYPES.POST,
  body: payload,
});
