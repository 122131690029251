import * as yup from 'yup';
import ERRORS from '../errorCopy';
import { commonRules } from '../rules';
import { getAppValidationRulesBySlugs } from '../../helpers';

export const preLookupValidationSchema = yup.object().shape({
  program: yup.string().required(ERRORS.FIELDS.PROGRAM_SELECT.REQUIRED),
  license: yup.string().required(ERRORS.FIELDS.LICENSE_SELECT.REQUIRED),
});

export const createLinkServiceFormValidationSchema = (
  agency,
  app,
  appParams
) => {
  const appRules = getAppValidationRulesBySlugs(agency.agencySlug, app.appSlug)
    .linkService;
  const validation = appParams.reduce((acc = {}, { name }) => {
    if (agency.agencyId === '10') {
      acc['dlIDNumber'] = commonRules['dlIDNumber'];
      acc['dob'] = commonRules['dob_dlr'];
      acc['last4ssn'] = commonRules['last4ssn'];
      acc['auditNumber'] = commonRules['auditNumber'];
    } else if (commonRules[name]) acc[name] = commonRules[name];
    if (appRules[name]) acc[name] = appRules[name];
    return acc;
  }, {});

  return yup.object().shape({ ...validation });
};
